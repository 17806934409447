<template>
  <v-container fluid>
    <v-widget title="Item" class="pb-0" style="height: calc(100vh - 136px); border-radius: 4px">
      <template v-slot:widget-content>
        <v-form v-model="valid" ref="formulario" lazy-validation>
          <v-container grid-list-md fluid class="pa-0" style="overflow: hidden auto; box-sizing: border-box; height: calc(100vh - 268px)">
            <v-row dense class="pt-1">
              <v-col cols="12" sm="3">
                <v-text-field required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" v-model="item.codigo" name="codigo" label="Código" id="codigo" :rules="[rules.obrigatorio]"></v-text-field>
              </v-col>

              <v-col cols="12" sm="9">
                <v-text-field
                  required
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  v-model="item.descricao"
                  name="descricao"
                  label="Descrição"
                  id="descricao"
                  counter="200"
                  :rules="[rules.obrigatorio, rules.maximo200Caracteres]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" />

              <v-col cols="12" sm="3">
                <as-campo-valor required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" v-model="item.valor" name="valor" label="Valor" id="valor" prefix="R$" :rules="[rules.valorMaiorQueZero]"></as-campo-valor>
              </v-col>

              <v-col cols="12" sm="3">
                <as-campo-valor
                  required
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  v-model="item.estoque"
                  name="estoque"
                  label="Estoque"
                  id="estoque"
                  :decimais="0"
                  :rules="[rules.valorMaiorQueZero]"
                ></as-campo-valor>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="2">
                <buscar-produto required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" v-model="item.produto_codigo" ref="codigoProduto" @produtoSelecionado="produtoSelecionado"></buscar-produto>
              </v-col>
              <v-col cols="12" sm="10">
                <v-text-field required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Descrição" v-model="item.produto_descricao" readonly></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="4">
                <v-autocomplete required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Estado" v-model="item.estado" :items="estados" @change="(sigla) => log('Sigla Estado:', sigla)"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="CNPJ/CPF" v-model="item.cnpjCpf" v-mask="['###.###.###-##', '##.###.###/####-##']"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <campo-cep required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" v-mask="'#####-###'" label="CEP" buscarcep @resultado="(endereco) => log(endereco)" v-model="item.cep" append-icon="map"></campo-cep>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Telefone" v-model="item.telefone" v-mask="'(##)####-####'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Celular" v-model="item.celular" v-mask="'(##)#####-####'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="E-mail" v-model="item.email"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-divider />

          <v-container fluid class="pa-0 pt-4 box shadow" style="text-align: end">
            <v-btn depressed class="mr-4" @click="cancelar">Cancelar</v-btn>
            <v-btn depressed color="primary" :loading="salvando" @click="salvar" :disabled="!valid">Salvar</v-btn>
          </v-container>
        </v-form>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import * as regras from '@/util/validacaoFormulario';

export default {
  directives: {
    mask,
  },

  data: () => ({
    valid: true,
    salvando: false,

    rules: { ...regras },
  }),

  computed: {
    ...mapState('crud', {
      item: 'item',
      carregando: 'carregando',
    }),

    ...mapGetters('cidadeEstado', {
      estados: 'estados',
      cidadesEstado: 'cidadesEstado',
      paises: 'paises',
    }),
  },

  methods: {
    async salvar() {
      if (this.$refs.formulario.validate()) {
        try {
          this.salvando = true;

          if (this.item._id) {
            await this.$store.dispatch('crud/atualizar', this.item);
          } else {
            await this.$store.dispatch('crud/salvar', this.item);
          }

          this.$router.push({ name: 'crudLista' });
        } finally {
          this.salvando = false;
        }
      }
    },

    cancelar() {
      this.$router.push({ name: 'crudLista' });
    },

    produtoSelecionado(produto) {
      this.item.produto_codigo = produto.codigo;
      this.item.produto_descricao = produto.descricao;
      this.item.valor = produto.valor;
    },

    log(...data) {
      console.log(...data);
    },
  },

  async created() {
    if (this.$router.currentRoute.name == 'crudEditar' && !this.item._id) {
      this.$router.push({ name: 'crudLista' });
    }
  },
};
</script>
